//@flow
import React from 'react';
import { Tooltip } from '@material-ui/core';

type Props = {
  asset_type_icon_url: string,
  asset_type_name: string,
  iconSize?: number,
};

/*
 * @deprecated - Should be using `AssetsTypeIcon` in ahura.
 *
 * Lightweight component designed to render an asset's icon.
 *
 * @param asset_type_icon_url - Backend asset type icon url
 * @param asset_type_name - Backend asset type name
 */
function UnifiedAssetTypeIcon({
  iconSize = 36,
  asset_type_icon_url,
  asset_type_name,
}: Props) {
  return (
    <Tooltip title={asset_type_name}>
      <img
        height={iconSize}
        width={iconSize}
        src={asset_type_icon_url}
        alt={`${asset_type_name} Icon`}
      />
    </Tooltip>
  );
}

export default React.memo<Props>(UnifiedAssetTypeIcon);
